import React from 'react';
import { Button, PlusIcon } from '@biss/react-horizon-web';
import { useAuthContext } from '@biss/react-auth-web';

import DataTrackList from '../../../../../shared/components/data-track-list';
import { DataTrackItem } from '../../../../../shared/components/data-track-list/data-track-list.definitions';
import AddDataTrackPopOver from '../../../../components/add-data-track-pop-over';
import DataTrackUploadModal from '../../../../components/data-track-upload-modal';
import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import { DEFAULT_DATA_TRACK_TYPES } from '../process-record-visualization.definitions';
import TrackedEvent from '../../../../../shared/common/tracked-event';
import useDeleteCustomDataTrack from '../../../../../shared/common/hooks/use-delete-custom-data-track';
import FKey from '../../../../../shared/common/feature-keys';
import useFeatureFlag from '../../../../../shared/common/hooks/use-feature-flag';
import ReleaseFeatureFlag from '../../../../../shared/common/types/release-feature-flag';
import BetaFeatureFlag from '../../../../../shared/common/types/beta-feature-flag';
import BKey from '../../../../../shared/common/beta-feature-keys';

import { ProcessRecordVisualizationDataTracksProps } from './process-record-visualization-data-tracks.definitions';

function ProcessRecordVisualizationDataTracks({
  coloredProcessRecords,
  selectedDataTrackTypes,
  onSelectionChange,
  canDeleteCustomDataTrack,
  canAddCustomDataTrack,
}: ProcessRecordVisualizationDataTracksProps) {
  const logger = useLogger();

  const { account } = useAuthContext();

  const { mutate: deleteDataTrack } =
    // the delete function is only possible on detail view where there is only one process record available
    useDeleteCustomDataTrack(coloredProcessRecords[0].processRecordId);

  const isDataTrackCalculationsEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.ANALYTICS_DATA_TRACK_CALCULATIONS),
  );

  const isDataTrackCalculationsBetaPackageEnabled = useFeatureFlag(
    new BetaFeatureFlag(
      BKey.ANALYTICS_DATA_TRACK_CALCULATION_BETA_PACKAGE_NAME,
      account?.organizationId,
    ),
  );

  // get all the available dataTrackTypes
  const allTypes = Array.from(
    new Set(coloredProcessRecords.flatMap((pr) => pr.dataTracks).map((dt) => dt.dataTrackType)),
  );

  // default data tracks that are available in this process record
  const availableDefaultDataTrackTypes = DEFAULT_DATA_TRACK_TYPES.filter((type) =>
    allTypes.includes(type),
  );

  // assemble the data track list display data (displayName, engineeringUnit, dots)
  const dataTrackItems: DataTrackItem[] = allTypes.map((dataTrackType) => {
    /** get all data tracks with the type {@link dataTrackType} */
    const dataTracksOfType = coloredProcessRecords
      .map((pr) => pr.dataTracks.map((dt) => ({ ...dt, processRecord: pr })))
      .flat()
      .filter((dt) => dt.dataTrackType === dataTrackType);

    // get the process record ids of those data tracks
    const dataTracksOfTypeProcessRecordIds = new Set(
      dataTracksOfType.map((dt) => dt.processRecord.processRecordId),
    );

    // get the color of those process records
    const dots = coloredProcessRecords
      .filter((pr) => dataTracksOfTypeProcessRecordIds.has(pr.processRecordId))
      .map((pr) => pr.color);

    const firstDataTrackOfType = dataTracksOfType.at(0);
    if (firstDataTrackOfType === undefined) {
      throw new Error(`Could not find a single data track of type ${dataTrackType}`);
    }

    return {
      // take the data out of the first data track of type {@link dataTrackType}
      dataTrackType: firstDataTrackOfType.dataTrackType,
      displayName: firstDataTrackOfType.displayName,
      engineeringUnit: firstDataTrackOfType.engineeringUnit,
      isCustom: firstDataTrackOfType.isCustom,
      isSaved: true,
      dots: dots.length === coloredProcessRecords.length ? [] : dots, // hide dots if data track is available for all process records
    } satisfies DataTrackItem;
  });

  const handleReset = () => {
    onSelectionChange(availableDefaultDataTrackTypes);
    logger.trackEvent(TrackedEvent.DeselectAllOrReset);
  };

  const handleDeselectAll = () => {
    onSelectionChange([]);
    logger.trackEvent(TrackedEvent.DeselectAllOrReset);
  };

  const handleSelect = (type: string) => {
    onSelectionChange(
      selectedDataTrackTypes.includes(type)
        ? selectedDataTrackTypes.filter((t) => t !== type)
        : [type, ...selectedDataTrackTypes],
    );
  };

  // delete a data track from a process record
  // only possible in detail view
  const handleDelete = ({ dataTrackType }: DataTrackItem) => {
    // the single process record in the single view
    const onlyProcessRecord = coloredProcessRecords[0];

    // find the data track that has the same type as the one that is supposed to be deleted
    const dataTrack = onlyProcessRecord.dataTracks.find((dt) => dt.dataTrackType === dataTrackType);

    if (!dataTrack) {
      throw new Error(
        `Data track with type "${dataTrackType}" could not be found within this process record.`,
      );
    }

    // type of the data track that is going to be deleted
    const deletableDataTrackType = dataTrack.dataTrackType;

    // was the data track selected before being deleted
    const isDeletableDataTrackSelected = selectedDataTrackTypes.includes(dataTrack.dataTrackType);

    if (isDeletableDataTrackSelected) {
      onSelectionChange(selectedDataTrackTypes.filter((t) => t !== deletableDataTrackType));
    }

    deleteDataTrack(dataTrack.dataTrackId, {
      // if the data track was selected before being deleted, select it again after an error
      onError: () =>
        isDeletableDataTrackSelected &&
        onSelectionChange(selectedDataTrackTypes.concat(deletableDataTrackType)),
    });
  };

  return (
    <DataTrackList
      items={dataTrackItems}
      selectedItems={selectedDataTrackTypes}
      onDeselectAll={handleDeselectAll}
      onReset={handleReset}
      onSelect={handleSelect}
      showTitle={false}
      canDeleteCustomDataTracks={canDeleteCustomDataTrack}
      onDelete={handleDelete}
      actions={
        canAddCustomDataTrack && (
          <div className="col-span-2 [&>button]:w-full">
            {isDataTrackCalculationsEnabled && isDataTrackCalculationsBetaPackageEnabled ? (
              <AddDataTrackPopOver processRecordId={coloredProcessRecords[0].processRecordId} />
            ) : (
              <DataTrackUploadModal
                processRecordId={coloredProcessRecords[0].processRecordId}
                trigger={
                  <Button
                    kind="secondary"
                    mood="neutral"
                    leftIcon={<PlusIcon />}
                    data-testid="add-data-track-button"
                  />
                }
              />
            )}
          </div>
        )
      }
    />
  );
}

export default ProcessRecordVisualizationDataTracks;
