import React, { useState } from 'react';
import { Input, Modal, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import useCalculateDataTrack from '../../common/hooks/use-calculate-data-track';
import { CalculationRequest } from '../../../shared/common/types/process-record/dto/calculator';
import RouteDefinition from '../../../shared/common/routes/routes.definitions';
import useSubmitDataTracks from '../../common/hooks/use-submit-data-tracks';

import {
  DataTrackFormulaFormData,
  DataTrackFormulaFormDirtyFields,
  DataTrackFormulaModalProps,
} from './data-track-formula-modal.definitions';
import { calculationRequestSchema } from './data-track-formula-modal.validators';
import DataTrackFormulaModalSuccess from './data-track-formula-modal-success';
import { addDataTrackToReactQueryLocalCache } from './data-track-formula-helpers';

function DataTrackFormulaModal({
  processRecordId,
  defaultOpen,
  onOpenChange,
  trigger,
  defaultFormData = { processRecordId },
  defaultDirtyFields = [],
  defaultIsDone = false,
}: DataTrackFormulaModalProps) {
  const queryClient = useQueryClient();

  const intl = useIntl();

  const { mutate: calculate, isPending, error } = useCalculateDataTrack();
  const { mutate: uploadDataTrack } = useSubmitDataTracks(processRecordId);

  const [formData, setFormData] = useState<DataTrackFormulaFormData>(defaultFormData);
  const [dirtyFields, setDirtyFields] = useState<DataTrackFormulaFormDirtyFields>(
    new Set(defaultDirtyFields),
  );

  const [isDone, setIsDone] = useState<boolean>(defaultIsDone);

  const validationResult = calculationRequestSchema.safeParse(formData);

  const handleModalOpenChange = (isOpen: boolean) => {
    onOpenChange?.(isOpen);
  };

  const handleCreateClick = () => {
    if (validationResult.success === false) {
      throw new Error('Expected create button to be disabled when data is not valid');
    }

    calculate(validationResult.data, {
      onSuccess(dataTrack) {
        setIsDone(true);

        uploadDataTrack([dataTrack]);
        addDataTrackToReactQueryLocalCache(queryClient, processRecordId, dataTrack);
      },
    });
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const name = e.currentTarget.name as keyof CalculationRequest;

    setDirtyFields((prev) => prev.add(name));

    setFormData((prev) => ({ ...prev, [name]: value }));

    // display name has the same value as the data track type
    if (name === 'resultDisplayName') {
      setFormData((prev) => ({ ...prev, resultDataTrackType: value }));
    }
  };

  /**
   * get the error message for a field if an error exists and the field is dirty
   */
  const getFieldError = (fieldName: keyof DataTrackFormulaFormData) => {
    // if the field is not dirty
    if (dirtyFields.has(fieldName) === false) {
      return undefined;
    }

    if (validationResult.success) {
      return undefined;
    }

    // eslint-disable-next-line no-underscore-dangle -- this is zod error formatting
    return validationResult.error.format()[fieldName]?._errors[0];
  };

  if (isDone) {
    return <DataTrackFormulaModalSuccess defaultOpen={defaultOpen} onOpenChange={onOpenChange} />;
  }

  return (
    <Modal
      size="md"
      defaultOpen={defaultOpen}
      onOpenChange={handleModalOpenChange}
      trigger={trigger}
      title={
        <FormattedMessage
          defaultMessage="Create Data Track with Formula"
          id="sw3zjq"
          description="Create Data Track with Formula: Title"
        />
      }
    >
      <Modal.Content>
        <div className="flex flex-col gap-4">
          {error && <NotificationMessage status="error">{error.message}</NotificationMessage>}

          <div className="flex flex-row gap-4">
            <Input
              name="resultDisplayName"
              value={formData.resultDisplayName}
              onChange={handleInputChange}
              expand="auto"
              error={getFieldError('resultDisplayName')}
              className="flex-auto"
              label={intl.formatMessage({
                defaultMessage: 'Data Track Name',
                description: 'Create Data Track with Formula: Data Track Name',
                id: 'SdG6nX',
              })}
              placeholder={intl.formatMessage({
                defaultMessage: 'e.g. DO.PV',
                description:
                  'Create Data Track with Formula: Placeholder for Data Track Name input',
                id: 'cMh7yf',
              })}
              disabled={isPending}
            />
            <Input
              name="resultEngineeringUnit"
              value={formData.resultEngineeringUnit}
              onChange={handleInputChange}
              expand="auto"
              error={getFieldError('resultEngineeringUnit')}
              className="flex-auto"
              label={intl.formatMessage({
                defaultMessage: 'Engineering Unit',
                description: 'Create Data Track with Formula: Engineering Unit',
                id: 'Xa7jFj',
              })}
              placeholder={intl.formatMessage({
                defaultMessage: 'e.g. 10^6 cell/ml',
                description:
                  'Create Data Track with Formula: Placeholder for Engineering Unit input',
                id: 'n/g7XY',
              })}
              disabled={isPending}
            />
          </div>
          <Input
            name="expression"
            onChange={handleInputChange}
            expand="auto"
            error={getFieldError('expression')}
            label={intl.formatMessage({
              defaultMessage: 'Formula',
              description: 'Create Data Track with Formula: Formula',
              id: 'QjC+b5',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'e.g. [DO.PV] * 1000',
              description: 'Create Data Track with Formula: Placeholder for Formula input',
              id: 'qDqbkf',
            })}
            disabled={isPending}
          />

          <p className="mt-3">
            <FormattedMessage
              description="Create Data Track with Formula: Documentation on how to use"
              defaultMessage="To learn how to calculate new data tracks with the formula editor {documentationLink}."
              id="U4Z21i"
              values={{
                documentationLink: (
                  <Link
                    to={`${RouteDefinition.Analytics}/process-records/data-track-calculations`}
                    className="text-blue"
                  >
                    <FormattedMessage
                      description="Create Data Track with Formula: click here"
                      defaultMessage="see the documentation"
                      id="FuOl5i"
                    />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Cancel"
              id="apAeZA"
              description="Create Data Track with Formula: Cancel"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          variant="highlight"
          onClick={handleCreateClick}
          disabled={validationResult.success === false || isPending}
        >
          <FormattedMessage
            defaultMessage="Create"
            id="jORDRH"
            description="Create Data Track with Formula: Create"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackFormulaModal;
