import { IntlShape } from 'react-intl';

import { ChartXAxisTimeStampFormat } from '../combined-chart.definitions';

import TimeRange from './combined-chart-x-axis-setup.definitions';

/**
 * Constructs a callback to format UNIX timestamps in a human readable format.
 * The timestamps get formatted depending on the users locale.
 *
 * @example
 * "18/08/2024 16:00"    // locale: de_DE
 * "8/18/2024 4:00 P.M." // locale: en_US
 */
const createXAxisTimeStampFullFormatter = (intl: IntlShape) => (timeStamp: number) =>
  intl.formatDate(timeStamp, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

/**
 * Constructs a formatter function to format timestamps in the format of `DDd HH:MM`.
 *
 * @example
 * "8d 16:00"      // locale: de_DE
 * "64d 4:00 P.M." // locale: en_US
 */
const createXAxisTimeStampAccumulativeDayFormatter = (intl: IntlShape) => (timeStamp: number) =>
  [
    `${Math.floor(timeStamp / (1_000 * 60 * 60 * 24))}d`,
    intl.formatDate(timeStamp, {
      hour: 'numeric',
      minute: 'numeric',
    }),
  ].join(' ');

/**
 * Constructs a formatter function to format timestamps in the format of `HHh MM:SS`.
 *
 * @example
 * "16h 35:20"
 * "130h 10:00"
 */
const createXAxisTimeStampAccumulativeHourFormatter = (intl: IntlShape) => (timeSpan: number) =>
  [
    `${Math.floor(timeSpan / (1_000 * 60 * 60))}h`,
    intl.formatDate(timeSpan, {
      minute: 'numeric',
      second: 'numeric',
    }),
  ].join(' ');

/**
 * decides the type of the formatter depending on the users locale and selected format option
 */
function getXAxisFormatter(timeStampFormat: ChartXAxisTimeStampFormat, intl: IntlShape) {
  if (timeStampFormat === ChartXAxisTimeStampFormat.FullDate) {
    return createXAxisTimeStampFullFormatter(intl);
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeDay) {
    return createXAxisTimeStampAccumulativeDayFormatter(intl);
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeHour) {
    return createXAxisTimeStampAccumulativeHourFormatter(intl);
  }

  throw new Error(`Unhandled arm. Scenario timeStampFormat=${timeStampFormat} was not handled.`);
}

/**
 * Constructs the x axis configuration for the combined chart based on the provided parameters.
 *
 * @param {TimeRange} range - start and stop value for the x axis
 * @param {ChartXAxisTimeStampFormat} timeStampFormat - decides the format of the generated time stamp strings
 * @param {IntlShape} intl - required to allow localized formatting of x axis labels
 */
function getXAxisEchartsConfig(
  { startTime, stopTime }: TimeRange,
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) {
  const formatter = getXAxisFormatter(timeStampFormat, intl);

  return {
    // the combined chart only uses a single x axis
    gridIndex: 0,
    type: 'time',
    min: startTime,
    max: stopTime,
    axisLabel: {
      rotate: 45,
      formatter,
    },
  };
}

export default getXAxisEchartsConfig;
