import { z } from 'zod';

import { DataTrackBase } from '../../../common/types/setup/data-track';

export const DUPLICATE_ENTRY_MSG = 'This Data Track name was already entered.';
export const TAKEN_TYPE_MSG = 'A Data Track with this name already exists.';
export const EMPTY_TYPE_MSG = 'Please enter a Data Track name.';

/**
 * validate each neccessary field of a datatrack and check if the datatrack is valid as a whole
 * @param datatrack
 */
export const validate = (
  { dataTrackType, engineeringUnit }: Pick<DataTrackBase, 'dataTrackType' | 'engineeringUnit'>,
  localTypes: string[],
  takenTypes: string[],
) => {
  const typeRes = z
    .string()
    .refine((val) => !(!val.trim().length && engineeringUnit && engineeringUnit.trim().length), {
      // should not be engineeringUnit without dataTrackType
      message: EMPTY_TYPE_MSG,
    })
    .refine(
      (val) =>
        localTypes.filter((item) => item.trim()).filter((type) => type.trim() === val.trim()) // should not be a duplicate entry
          .length <= 1,
      {
        message: DUPLICATE_ENTRY_MSG,
      },
    )
    .refine(
      (val) => takenTypes.map((type) => type.trim()).includes(val.trim()) === false, // should not already exist
      {
        message: TAKEN_TYPE_MSG,
      },
    )
    .safeParse(dataTrackType);

  return {
    fields: { typeRes },
    isValid: typeRes.success,
  };
};
