import React from 'react';

import { ToggleSwitch } from '@biss/react-horizon-web';

import { FormattedMessage } from 'react-intl';

import { SetupEventListSupscriptionProps } from './setup-event-list-subscription.definitions';

function SetupEventListSubscription({
  disabled = false,
}: SetupEventListSupscriptionProps): React.FunctionComponentElement<SetupEventListSupscriptionProps> | null {
  const [notificationsEnabled, setNotificationsEnabled] = React.useState(false);
  const handleSwitchNotificationsChange = () => {
    setNotificationsEnabled((prev) => !prev);
  };

  return (
    <ToggleSwitch
      data-testid="email-notification-toggle-switch"
      id="email-notification"
      labelPlacement="tail"
      label={
        <FormattedMessage
          description="Label for toggle switch to enable e-mail notifications"
          defaultMessage="Enable e-mail notifications for all important events"
          id="rbJnpu"
        />
      }
      disabled={disabled}
      checked={notificationsEnabled}
      onChange={handleSwitchNotificationsChange}
    />
  );
}

export default SetupEventListSubscription;
