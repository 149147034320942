import { IntlShape } from 'react-intl';

import { StructuredProcessRecordDescriptor } from '../../common/types/process-record';
import truthy from '../../common/utils/truthy';

export function seek(
  needle: string,
  {
    deviceName,
    displayName,
    processRecordId,
    title,
    unit,
    comment,
    operator,
    startTimestamp,
    stopTimestamp,
    uploadTimestamp,
    inoculationTimestamp,
  }: StructuredProcessRecordDescriptor,
  intl: IntlShape,
): boolean {
  const agnosticNeedle = needle.trim().toLocaleLowerCase();

  const hasNeedle = (within: string) => within.trim().toLocaleLowerCase().includes(agnosticNeedle);

  if (
    [deviceName, displayName, processRecordId, title, unit]
      .concat([comment, operator].filter(truthy))
      .some(hasNeedle)
  ) {
    return true;
  }

  const timeHasNeedle = (ts: Date) =>
    hasNeedle(intl.formatDate(ts)) || hasNeedle(intl.formatTime(ts));

  if (
    [startTimestamp, stopTimestamp]
      .concat([inoculationTimestamp, uploadTimestamp].filter(truthy))
      .some(timeHasNeedle)
  ) {
    return true;
  }

  return false;
}

export function filterProcessRecords(
  searchParam: string,
  intl: IntlShape,
  processRecords?: StructuredProcessRecordDescriptor[],
) {
  return (
    (searchParam.length > 0
      ? processRecords?.filter((pr) => seek(searchParam, pr, intl))
      : processRecords) ?? []
  );
}
