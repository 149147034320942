import React, { useEffect, useRef, useState } from 'react';
import { EChartsType, init } from 'echarts';
import { useIntl } from 'react-intl';

import { CombinedChartProps, ChartXAxisTimeStampFormat } from './combined-chart.definitions';
import { COMBINED_CHART_TOTAL_HEIGHT } from './combined-chart.config';
import useZoom from './combined-chart-zoom-setup';
import {
  getDatasetEchartsConfig,
  getGridEchartsConfig,
  getLegendEchartsConfig,
  getSeriesEchartsConfig,
  getTooltipEchartsConfig,
} from './combined-chart.helpers';
import getXAxisEchartsConfig from './combined-chart-x-axis-setup';
import useYAxis from './combined-chart-y-axis-setup';

// TODO: (BIOCL-4589): allow user input x axis label formatter
function CombinedChart({
  data,
  startTime,
  stopTime,
  timeStampFormat = ChartXAxisTimeStampFormat.FullDate,
}: CombinedChartProps) {
  if (data.length === 0) {
    throw new TypeError(
      `The combined chart component cannot be called with an empty data array!
      Render a different component (e.g. the placeholder chart) when no data is present.`,
    );
  }

  const intl = useIntl();

  const root = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<EChartsType>();

  const { onZoomCallback, echartsZoomConfig, echartsResetZoomButtonGraphic } = useZoom(data.length);
  const { modal, echartsYAxisConfig, yAxisClickCallback } = useYAxis(
    data.map((entry) => ({ name: entry.name, label: entry.yAxisLabel })),
  );

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   *
   *  The key should contain of unique features of the data prop and should therefore only
   *    trigger dependent code when the data actually changes.
   *  See https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
   */
  const dataCacheKey = data
    .map((entry) => `${entry.id}${entry.dataPoints.length}`)
    .join()
    .concat(data.length.toString());

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const yAxisRangeCacheKey = echartsYAxisConfig
    .map((entry) => `${entry.name}${entry.name}${entry.min}${entry.max}`)
    .join()
    .concat(echartsYAxisConfig.length.toString());

  /** Returns all echarts options that are dependent on the data prop. */
  const getDataOptions = () => ({
    legend: getLegendEchartsConfig(data),
    grid: getGridEchartsConfig(data.length),
    tooltip: getTooltipEchartsConfig(data.length),
    dataset: getDatasetEchartsConfig(data),
    series: getSeriesEchartsConfig(data),
    yAxis: echartsYAxisConfig,
    dataZoom: echartsZoomConfig,
    graphic: [echartsResetZoomButtonGraphic],
  });

  /** Returns all echarts options that are dependent on the startTime and stopTime props. */
  const getStartAndStopTimeOptions = () => ({
    xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
  });

  // initialize
  useEffect(() => {
    // this will attempt to initialize the chart twice in strict mode but fail gracefully with a warning
    const initializedChart = init(root.current, 'horizon-web', {
      height: COMBINED_CHART_TOTAL_HEIGHT,
    });

    // set initial chart options
    initializedChart.setOption(
      {
        animation: false,
        ...getStartAndStopTimeOptions(),
        ...getDataOptions(),
      },
      { lazyUpdate: true },
    );
    // add event listeners
    initializedChart.on('datazoom', onZoomCallback);

    initializedChart.on('click', (params: object) => {
      yAxisClickCallback(params);
    });

    const handleWindowResize = () => initializedChart.resize();
    window.addEventListener('resize', handleWindowResize);

    setChart(initializedChart);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // update settings dependent on the provided data
  useEffect(() => {
    chart?.setOption(
      {
        ...getDataOptions(),
      },
      // When data is selected/deselected all y axis related options need to be overridden.
      //  Otherwise y axes are rendered even though the related data is not shown.
      { lazyUpdate: true, replaceMerge: ['yAxis', 'series', 'dataZoom'] },
    );
  }, [dataCacheKey]);

  // update chart settings dependent on the start and stop timestamps
  useEffect(() => {
    chart?.setOption(
      {
        ...getStartAndStopTimeOptions(),
      },
      { lazyUpdate: true },
    );
  }, [startTime, stopTime, timeStampFormat]);

  // update the y axis when the y axis configuration changes
  useEffect(() => {
    chart?.setOption(
      {
        yAxis: echartsYAxisConfig,
      },
      { lazyUpdate: true },
    );
  }, [yAxisRangeCacheKey]);

  return (
    <>
      {modal}
      <div
        data-testid="process-record-analytics-combined-chart"
        ref={root}
        style={{
          // has to be set so that certain elements do not need to be offset manually
          width: '100%',
          height: `${COMBINED_CHART_TOTAL_HEIGHT}px`,
        }}
      />
    </>
  );
}

export default CombinedChart;
