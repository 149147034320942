import React from 'react';

import useForceRerender from '../../../common/hooks/use-force-rerender';
import useHorizontalZoom from '../../../components/time-series-chart/use-horizontal-zoom';
import { useKeyboard } from '../../../common/hooks/use-keyboard';
import useShouldEnableTouch from '../../../components/time-series-chart/use-should-enable-touch';
import { useFormatXAxisTicks } from '../../../../analytics/scenes/process-record-detail/process-record-visualization/process-record-visualization-chart/process-record-visualization-chart.helpers';
import {
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_RESET_ZOOM_BUTTON_DIMENSIONS,
  COMBINED_CHART_RIGHT_OFFSET_PX,
  COMBINED_CHART_TOTAL_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_INSIDE_ID,
  COMBINED_CHART_X_AXIS_ZOOM_SLIDER_ID,
  COMBINED_CHART_Y_AXIS_ZOOM_SLIDER_ID,
} from '../combined-chart.config';

import ZoomRange from './combined-chart-zoom-setup.definitions';

/**
 * Resets all given zoom levels to the initial range 0% to 100%.
 *  A rerender is forced after resetting the zoom levels to update the chart visualization.
 */
function useResetZoom(zoomStates: Array<React.MutableRefObject<ZoomRange>>) {
  const rerender = useForceRerender();

  return () => {
    zoomStates.forEach((state) => {
      // eslint-disable-next-line no-param-reassign -- mutation of an object reference
      state.current = { start: 0, end: 100 };
    });

    rerender();
  };
}

/**
 * Provides a callback that sets the current state of the zoom levels.
 *  It needs to be added to the echarts callbacks as follow:
 *
 * @example
 *    import { init } from 'echarts';
 *
 *    const root = useRef<HTMLDivElement>(null);
 *    const chart = init(root.current)
 *    chart?.on('datazoom', onZoomCallback);
 */
function getOnZoomCallback(
  zoomX: React.MutableRefObject<ZoomRange>,
  zoomY: React.MutableRefObject<ZoomRange>,
) {
  function onZoomCallback(zoom: unknown) {
    // TODO: (BIOCL-4457) fix typing of data dataZoom object
    const { start, end, dataZoomId, batch } = zoom as {
      start: number;
      end: number;
      dataZoomId: string;
      batch?: Array<{ start?: number; end?: number }>;
    };

    if (dataZoomId === COMBINED_CHART_X_AXIS_ZOOM_SLIDER_ID) {
      // eslint-disable-next-line no-param-reassign -- mutation of an object reference
      zoomX.current = { start, end };
    } else if (dataZoomId === COMBINED_CHART_Y_AXIS_ZOOM_SLIDER_ID) {
      // eslint-disable-next-line no-param-reassign -- mutation of an object reference
      zoomY.current = { start, end };
    } else if (batch) {
      const last = batch[batch.length - 1];
      // eslint-disable-next-line no-param-reassign -- mutation of an object reference
      zoomX.current = {
        start: last.start ?? zoomX.current.start,
        end: last.end ?? zoomX.current.end,
      };
    } else {
      throw new TypeError(`Echart on zoom callback: Unexpected Input ${zoom}`);
    }
  }

  return onZoomCallback;
}

/**
 * Configures the Echarts settings for the y axis
 *  depending on the amount of displayed lines and current zoom level.
 *
 * @param {number} amountOfLines the amount of lines that are displayed in the combined chart.
 * @param {React.MutableRefObject<ZoomRange>} zoomX the current zoom level on the x axis
 * @param {React.MutableRefObject<ZoomRange>} zoomY the current zoom level on the y axis
 * @returns y axis configuration object to configure Echarts with
 */
function useEchartsDataZoomConfig(
  amountOfLines: number,
  zoomX: React.MutableRefObject<ZoomRange>,
  zoomY: React.MutableRefObject<ZoomRange>,
) {
  const keyboard = useKeyboard();
  const shouldEnableTouch = useShouldEnableTouch();

  // TODO: (BIOCL-4457) fix naming and import
  const dateFormatter = useFormatXAxisTicks();

  return [
    {
      realtime: true,
      id: COMBINED_CHART_X_AXIS_ZOOM_SLIDER_ID,
      type: 'slider',
      start: zoomX.current.start,
      end: zoomX.current.end,
      orient: 'horizontal',
      // the combined chart only contains a single x axis
      xAxisIndex: [0, 0],
      filterMode: 'none',
      left: COMBINED_CHART_LEFT_OFFSET_PX * amountOfLines,
      right: COMBINED_CHART_RIGHT_OFFSET_PX,
      showDataShadow: false,
      height: COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
      bottom: 10,
      zoomLock: false,
      labelFormatter: dateFormatter,
    },
    {
      type: 'inside',
      id: COMBINED_CHART_X_AXIS_ZOOM_INSIDE_ID,
      start: zoomX.current.start,
      end: zoomX.current.end,
      orient: 'horizontal',
      // the combined chart only contains a single x axis
      xAxisIndex: [0, 0],
      rangeMode: ['value', 'percent'],
      zoomLock: !(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held),
      zoomOnMouseWheel: 'shift',
      moveOnMouseWheel: 'ctrl',
      moveOnMouseMove: true,
      preventDefaultMouseMove: false,
      filterMode: 'none',
      labelFormatter: dateFormatter,
    },
    {
      type: 'slider',
      id: COMBINED_CHART_Y_AXIS_ZOOM_SLIDER_ID,
      start: zoomY.current.start,
      end: zoomY.current.end,
      rangeMode: ['percent', 'percent'],
      // the combined chart contains a y axis for each line in the chart
      //  the slider controls all y axes [0, 1, ..., data.length]
      yAxisIndex: Array.from(Array(amountOfLines).keys()),
      orient: 'vertical',
      filterMode: 'none',
      show: true,
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      right: 5,
    },
  ];
}

/**
 * Configures a button to reset the zoom level. Intended to be included in the echarts configuration.
 *
 * @param amountOfAxes the amount of y axes that are currently displayed.
 *  This is required to calculate the correct distance to the left border of the chart
 * @param resetZoom callback that is called when the reset button is clicked
 * @returns graphics object that can be used to configure echarts with
 */
function getResetZoomButtonGraphic(amountOfAxes: number, resetZoom: () => void) {
  const buttonDistanceFactor = 1.5;
  return {
    type: 'image',
    style: {
      image: '/assets/icons/resetZoom.png',
      x:
        amountOfAxes * COMBINED_CHART_LEFT_OFFSET_PX -
        buttonDistanceFactor * COMBINED_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.width,
      y: COMBINED_CHART_TOTAL_HEIGHT - COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
      width: COMBINED_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.width,
      height: COMBINED_CHART_RESET_ZOOM_BUTTON_DIMENSIONS.height,
    },
    onclick() {
      resetZoom();
    },
  };
}

/**
 * Configuration to set up the zoom for the combined chart.
 *
 * @param amountOfYAxes amount of currently displayed y axes.
 *  This is required to configure the charts layout and the echarts zoom configuration.
 */
function useZoom(amountOfYAxes: number) {
  // TODO: (BIOCL-4457) move zoom hook into chart "package" and fix naming (zoomY -> useHORIZONTALZoom)
  const zoomX = useHorizontalZoom();
  const zoomY = useHorizontalZoom();
  const resetZoom = useResetZoom([zoomX, zoomY]);

  const echartsZoomConfig = useEchartsDataZoomConfig(amountOfYAxes, zoomX, zoomY);
  const echartsResetZoomButtonGraphic = getResetZoomButtonGraphic(amountOfYAxes, resetZoom);
  const onZoomCallback = getOnZoomCallback(zoomX, zoomY);

  return { onZoomCallback, echartsZoomConfig, echartsResetZoomButtonGraphic };
}

export default useZoom;
