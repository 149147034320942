import { DataTrackTimeAlignment } from '../process-record-visualization.definitions';
import { SeriesName } from '../../../../../shared/components/time-series-chart';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import { ColoredProcessRecord } from '../../../process-record-comparison/process-record-comparison.definitions';

export const YAXIS_LABEL_DECIMALS = 2;

export interface ProcessRecordVisualizationChartProps {
  inSingleView: boolean;
  timeAlignment: DataTrackTimeAlignment;
  selectedDataTrackTypes: string[];
  coloredProcessRecords: ColoredProcessRecord[];

  defaultYAxisRanges?: Map<SeriesName, YAxisRange>;
}
