import React, { FunctionComponentElement, useState } from 'react';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { Tile } from '@biss/react-horizon-web';

import { useFormatDateTime } from '../../../../shared/common/hooks/use-format-time';
import TimeSeriesChart, { SeriesName } from '../../../../shared/components/time-series-chart';
import {
  SHORTCUTS,
  Y_AXIS_TIP,
} from '../../../../shared/components/time-series-chart/time-series-chart.definitions';

import {
  getSeriesLegend,
  yAxisFormatter,
} from '../setup-details-content/setup-details-content.helpers';

import TileWithSkeleton from '../../../../shared/tile-with-skeleton';

import useFeatureFlag from '../../../../shared/common/hooks/use-feature-flag';
import ReleaseFeatureFlag from '../../../../shared/common/types/release-feature-flag';
import FKey from '../../../../shared/common/feature-keys';
import { SplitChart } from '../../../../shared/charts';

import { Y_AXIS_RANGE } from '../../../common/types/setup';

import { YAxisRange } from '../../setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

import { SetupTimeSeriesChartProps } from './setup-time-series-chart.definitions';
import { getYAxisRanges } from './setup-time-series-chart.helpers';

function SetupTimeSeriesChart({
  startTimestamp,
  stopTimestamp,
  selectedDataTracks,
  seriesLegendSelected,
  seriesMarkLines,
  toggleSeriesLegendSelected,
  updateDataTrackColors,
  isShowLoading,
  series,
  dataTracks,
}: SetupTimeSeriesChartProps): FunctionComponentElement<SetupTimeSeriesChartProps> {
  const xAxisFormatter = useFormatDateTime();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const storageYAxis = useReadLocalStorage<Record<SeriesName, YAxisRange>>(Y_AXIS_RANGE);

  // range of the viewable and zoom-able area on the y axis of the chart
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    storageYAxis ? getYAxisRanges(storageYAxis) : new Map(),
  );

  const showRefactoredChartComponent = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_REFACTORING_SPLIT_CHART),
  );

  const seriesLegend = getSeriesLegend(dataTracks, selectedDataTracks);
  const stopTime = stopTimestamp?.getTime() || new Date().getTime();

  const toggleSplitView = (isCombined: boolean) => {
    if (isCombined) {
      updateDataTrackColors();
    }
  };

  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  return (
    <TileWithSkeleton
      className={`${isMobile ? 'min-h-[383px]' : 'min-h-[507px]'} p-4`}
      isLoading={isShowLoading}
      data-testid="detail-graph"
    >
      {startTimestamp && (
        <>
          <TimeSeriesChart
            series={series}
            startTime={startTimestamp?.getTime()}
            stopTime={stopTime}
            combinedGraph
            showTooltip
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={yAxisFormatter}
            seriesMarkLines={seriesMarkLines}
            toggleSplitView={toggleSplitView}
            showToggleSplit
            showZoom
            showToolbox
            showLegend
            seriesLegend={seriesLegend}
            legendSelected={seriesLegendSelected}
            toggleLegendSelected={toggleSeriesLegendSelected}
            yAxisRanges={yAxisRanges}
            onYAxisRangeChange={handleYAxisRangeChange}
            setYAxisRanges={setYAxisRanges}
            showSideToolbox
            showDetailModal
          />
          <Tile.Footer>
            {SHORTCUTS}
            {Y_AXIS_TIP}
          </Tile.Footer>
          {showRefactoredChartComponent && <SplitChart />}
        </>
      )}
    </TileWithSkeleton>
  );
}

export default SetupTimeSeriesChart;
