import React, { FunctionComponentElement } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useDataTracksFromTimestamp } from '../../../../common/hooks/use-data-tracks-from-timestamp';
import DataPointCreateModal from '../../../../../shared/components/data-point-create-modal';
import useAddDataPoints from '../../../../common/hooks/use-add-data-points';
import { DataPoint } from '../../../../../shared/components/data-point-create-modal/data-point-create-modal.definitions';
import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../../shared/common/tracked-event';

import useDeleteDataPoints from '../../../../common/hooks/use-delete-data-points';

import QKey from '../../../../../shared/common/hooks/keys';

import { AddEditDataPointsProps } from './add-edit-data-points.definition';
import {
  formatDataPoints,
  formatRemovedDataPoints,
  getDataPoints,
} from './add-edit-data-points.helpers';

function AddEditDataPoints({
  setupId,
  controlProcedureId,
  dataTrack,
  open,
  onChangeModal,
}: AddEditDataPointsProps): FunctionComponentElement<AddEditDataPointsProps> {
  const logger = useLogger();
  const queryClient = useQueryClient();
  const [deletedDataPointsCount, setDeletedDataPointsCount] = React.useState<number>();

  const { dataTrackId } = dataTrack;
  const { data: dataTrackDataPoints, isFetching: isLoadingDataPoints } = useDataTracksFromTimestamp(
    controlProcedureId,
    [{ id: dataTrackId }],
    setupId,
  );

  const {
    mutate: addDataTrackDataPoints,
    isPending,
    isError: isAddDataPointsError,
  } = useAddDataPoints({
    controlProcedureId,
    setupId,
  });

  const {
    mutate: deleteDataTrackDataPoints,
    isPending: isDeletingPending,
    isError: isDeleteDataPointsError,
  } = useDeleteDataPoints({
    controlProcedureId,
    setupId,
    dataTrackId,
  });
  const dataPoints = getDataPoints(dataTrackId, dataTrackDataPoints);

  const updateDataTrack = () => {
    queryClient.invalidateQueries({
      queryKey: [QKey.SETUPS, setupId, dataTrackId],
    });
  };

  const addDataPoints = (newDataPoints: DataPoint[]) => {
    if (!newDataPoints.length) {
      return;
    }
    const newDataTrackPoints = formatDataPoints(newDataPoints);
    addDataTrackDataPoints([{ dataTrackId, dataPoints: newDataTrackPoints }], {
      onSuccess: () => {
        updateDataTrack();
        onChangeModal(false);
      },
      onError: async () => {
        if (deletedDataPointsCount) {
          updateDataTrack();
        }
      },
    });
    logger.trackEvent(TrackedEvent.AddDataPointsToCustomDataTrack);
  };

  const deleteDataPoints = (newDataPoints: DataPoint[], removedDataPoints: string[]) => {
    const dataTrackPoints = formatRemovedDataPoints(removedDataPoints);
    deleteDataTrackDataPoints(dataTrackPoints, {
      onSuccess: () => {
        setDeletedDataPointsCount(removedDataPoints.length);
        if (newDataPoints.length) {
          addDataPoints(newDataPoints);
        } else {
          updateDataTrack();
          onChangeModal(false);
        }
      },
    });
    logger.trackEvent(TrackedEvent.DeleteDataPointsFromCustomDataTrack);
  };

  const saveDataPoints = (newDataPoints: DataPoint[], removedDataPoints: string[]) => {
    if (!deletedDataPointsCount && removedDataPoints.length) {
      deleteDataPoints(newDataPoints, removedDataPoints);
    } else {
      addDataPoints(newDataPoints);
    }
  };

  return (
    <DataPointCreateModal
      dataPoints={dataPoints}
      dataTrack={dataTrack}
      open={open}
      onChangeModal={onChangeModal}
      isLoading={isLoadingDataPoints}
      saveDataPoints={saveDataPoints}
      isPending={isPending || isDeletingPending}
      isAddDataPointsError={isAddDataPointsError}
      isDeleteDataPointsError={isDeleteDataPointsError}
    />
  );
}

export default AddEditDataPoints;
