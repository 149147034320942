import { palettes } from '@biss/react-horizon-web';

import { DataPointObject, DataTrackType } from '../../../shared/common/types/process-record';

import { DEFAULT_DATA_TRACK_TYPES } from '../setup-overview/setup-overview.definitions';
import { filterColors, getGraphColors } from '../../common/helpers';
import { DataTrackDescriptor, DataTrackId } from '../../../shared/common/types/setup/data-track';
import { DEFAULT_FRACTIONAL_DIGITS } from '../../common/types/data-track';
import { AUTO_UPDATE_NUMBER_DETAILS } from '../../common/types/setup';

import { ApiError } from '../../../shared/common/types/api-error/api-error';

import {
  DataTrackFromTimestampItem,
  StructuredSetup,
  StructuredSetupDescriptor,
} from '../../../shared/common/types/setup';

import { WorkflowInfo } from './setup-navigation/setup-navigation.definitions';

export const defaultColorKey = 'DEFAULT';
export const SP = 'SP';
export const PV = 'PV';

export const defaultDataTracks = DEFAULT_DATA_TRACK_TYPES.reduce(
  (acc: Record<DataTrackType, string>, type: DataTrackType, index: number) => {
    acc[type] = Object.keys(palettes.categorical_unique)[index];
    return acc;
  },
  {},
);

export function isSpOrPvType(type: string) {
  return type === PV || type === SP;
}

export function deleteDataTrack(
  selectedDataTracks: Record<DataTrackType, string>,
  type: DataTrackType,
) {
  const dataTracks = { ...selectedDataTracks };
  delete dataTracks[type];
  return dataTracks;
}

export function splitDataTrackType(dataTrackType: DataTrackType) {
  return dataTrackType.split('.');
}

export function getUniqueColorName(selectedDataTracks: Record<DataTrackType, string>) {
  const colors = getGraphColors();
  const dataTrackColors = Object.values(selectedDataTracks);

  const availableColors = filterColors(colors, dataTrackColors);
  return availableColors.length
    ? availableColors[0]
    : colors[dataTrackColors.length % colors.length];
}

export function getDataTrackColorName(
  dataTrackType: DataTrackType,
  selectedDataTracks: Record<DataTrackType, string>,
) {
  const dataTrackTypes = Object.keys(selectedDataTracks);
  const [track, type] = splitDataTrackType(dataTrackType);
  if (selectedDataTracks[dataTrackType]) {
    return selectedDataTracks[dataTrackType];
  }
  if (isSpOrPvType(type)) {
    const dataTrackKey = dataTrackTypes.find((item) => {
      const [currentTrack, currentType] = splitDataTrackType(item);
      return track === currentTrack && isSpOrPvType(currentType);
    });
    if (dataTrackKey) {
      return selectedDataTracks[dataTrackKey];
    }
  }
  return getUniqueColorName(selectedDataTracks);
}

export function getSelectedDataTracks(
  dataTracks: Record<DataTrackType, boolean> | null,
): Record<string, string> {
  if (dataTracks && Object.keys(dataTracks).length) {
    return Object.keys(dataTracks).reduce(
      (acc: Record<DataTrackType, string>, type: DataTrackType) => {
        if (dataTracks[type]) {
          acc[type] = getDataTrackColorName(type, acc);
        }
        return acc;
      },
      {},
    );
  }
  return defaultDataTracks;
}

export function formatDataPoints(
  dataPoints: DataPointObject[],
  fractionalDigits = DEFAULT_FRACTIONAL_DIGITS,
  timeDifference = 0,
): DataPointObject[] {
  return dataPoints.map(({ ts, v }) => ({
    ts: ts + timeDifference,
    v: Number(v.toFixed(fractionalDigits)),
  }));
}

export function updateDataTrackColors(selectedDataTracks: Record<DataTrackType, string>) {
  const dataTracks = { ...selectedDataTracks };
  const isDuplicates = Object.values(dataTracks).some(
    (current, index, arr) => arr.indexOf(current) !== index,
  );
  if (isDuplicates) {
    Object.keys(dataTracks).forEach((key: string) => {
      const [track, type] = splitDataTrackType(key);
      const duplicates = Object.keys(dataTracks).filter((item) => {
        const [currentTrack, currentType] = splitDataTrackType(item);
        const isSPorPVpoints = isSpOrPvType(currentType) && isSpOrPvType(type);
        const isDifferentType =
          track !== currentTrack || (track === currentTrack && !isSPorPVpoints);
        const isSameColor = key !== item && dataTracks[key] === dataTracks[item];
        return isSameColor && isDifferentType;
      });
      if (duplicates.length > 0) {
        const color = getUniqueColorName(dataTracks);
        duplicates.forEach((duplicate) => {
          dataTracks[duplicate] = color;
        });
      }
    });
  }
  return dataTracks;
}

export function getRequestedDataTrackId(
  type: DataTrackType,
  dataTracks?: Array<DataTrackDescriptor>,
): DataTrackId | undefined {
  const dataTrack = dataTracks?.find((track: DataTrackDescriptor) => track.dataTrackType === type);

  return dataTrack?.dataTrackId;
}

export function getRequestedDataTrackIds(
  selectedDataTracks: DataTrackType[],
  dataTracks?: Array<DataTrackDescriptor>,
): Array<DataTrackId> | undefined {
  if (!dataTracks) {
    return undefined;
  }
  const dataTrackIds: Array<DataTrackId> = [];
  selectedDataTracks.forEach((selectedDataTrackType) => {
    const [track, type] = splitDataTrackType(selectedDataTrackType);
    if (isSpOrPvType(type)) {
      const trackIds = dataTracks
        .filter((dataTrack) => {
          const [dataTrackType, dataTrackPoin] = splitDataTrackType(dataTrack.dataTrackType);
          return (
            track === dataTrackType &&
            isSpOrPvType(dataTrackPoin) &&
            !dataTrackIds.includes(dataTrack.dataTrackId)
          );
        })
        .map((item) => item.dataTrackId);
      if (trackIds) {
        dataTrackIds.push(...trackIds);
      }
    } else {
      const dataTrack = dataTracks.find((item) => item.dataTrackType === selectedDataTrackType);
      if (dataTrack) {
        dataTrackIds.push(dataTrack.dataTrackId);
      }
    }
  });
  return dataTrackIds;
}

export function sortDataTracksByType(dataTracks: DataTrackDescriptor[]) {
  return dataTracks.sort((a, b) => a.dataTrackType.localeCompare(b.dataTrackType));
}

export function getWorkflowInfo(
  setups?: StructuredSetupDescriptor[],
  workflowId?: string,
): WorkflowInfo | undefined {
  if (!setups || !workflowId) {
    return undefined;
  }
  const workflowSetups = setups.filter((setup) => setup.workflowId === workflowId);
  if (!workflowSetups.length) {
    return undefined;
  }
  const units = workflowSetups.map((item) => ({ unit: item.unit, setupId: item.setupId }));
  units.sort((a, b) =>
    a.unit.localeCompare(b.unit, undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  );
  return {
    title: workflowSetups[0].title,
    deviceName: workflowSetups[0].deviceName,
    setups: units,
  };
}

export function getDataTracks(setup?: StructuredSetup): DataTrackDescriptor[] {
  if (!setup?.dataTracks) {
    return [];
  }
  return sortDataTracksByType(setup.dataTracks);
}

export function isGapRecovery(gapRecoveryTimestamp?: string | null) {
  return !!gapRecoveryTimestamp;
}

export function getDataTrackIds(
  dataTracksTimestamp: DataTrackFromTimestampItem[],
): DataTrackFromTimestampItem[] {
  return dataTracksTimestamp.map((track: DataTrackFromTimestampItem) => ({ id: track.id }));
}

export function getDataTracksConflict(saveDataTracksError?: ApiError | null): string[] {
  if (!saveDataTracksError) {
    return [];
  }
  const dataTrackTypesError = saveDataTracksError.values.dataTrackTypes;
  if (typeof dataTrackTypesError !== 'string') {
    return [];
  }

  return dataTrackTypesError.split('; ');
}

export function isFromTimestamp(counter: number, gapRecoveryTimestamp?: string | null) {
  return counter < AUTO_UPDATE_NUMBER_DETAILS || Boolean(gapRecoveryTimestamp);
}

export function handleDeleteCustomDataTrackSuccess(
  dataTrackType: DataTrackType,
  selectedDataTracks: Record<DataTrackType, string>,
  setSelectedDataTracks: (selectedTracks: Record<DataTrackType, string>) => void,
) {
  // check if the data track was selected before being deleted
  const isDeletableDataTrackSelected = Object.keys(selectedDataTracks).includes(dataTrackType);

  if (isDeletableDataTrackSelected) {
    const selected = { ...selectedDataTracks };
    delete selected[dataTrackType];
    setSelectedDataTracks(selected);
  }
}

export function getDataTrackId(dataTracks: DataTrackDescriptor[], dataTrackType: DataTrackType) {
  const dataTrack = dataTracks.find((dt) => dt.dataTrackType === dataTrackType);
  if (!dataTrack) {
    throw new Error(
      `Data track with type "${dataTrackType}" could not be found within this setup.`,
    );
  }

  return dataTrack.dataTrackId;
}

export function showAddDataPointsModal(
  isSetup: boolean,
  isDataPointsModalOpen: boolean,
  isDataTrack: boolean,
): boolean {
  return isSetup && isDataPointsModalOpen && isDataTrack;
}

export function getStopTimestamp(
  stopTimestampSetup?: Date,
  stopTimestampUpdatedSetup?: Date,
): Date | undefined {
  return stopTimestampSetup || stopTimestampUpdatedSetup;
}

export function getDataTracksDescriptors(
  dataTracks?: DataTrackDescriptor[],
): DataTrackDescriptor[] {
  return dataTracks || [];
}

export function isGetDataPoints(
  autoUpdate: boolean,
  isDisconnected: boolean,
  stopTimestamp?: Date,
): boolean {
  return autoUpdate && !isDisconnected && !stopTimestamp;
}

export function isInvalidateSetup(
  isDocumentVisiblePrev: boolean,
  isDocumentVisible: boolean,
  autoUpdate: boolean,
): boolean {
  return !isDocumentVisiblePrev && isDocumentVisible && !autoUpdate;
}
