import React from 'react';

import { CombinedChart } from '../../../../../shared/charts';
import PlaceholderChart from '../../../../../shared/charts/placeholder-chart';
import { PROCESS_RECORD_COLORS } from '../../../process-record-comparison/process-record-comparison.definitions';
import ProcessRecordVisualizationChart from '../process-record-visualization-chart';

import { ProcessRecordVisualizationChartSelectionProps } from './process-record-visualization-chart-selection.definitions';

function ProcessRecordVisualizationChartSelection({
  chartType,
  selectedDataTrackTypes,
  coloredProcessRecords,
  timeAlignment,
  totalProcessRecordCount,
}: ProcessRecordVisualizationChartSelectionProps) {
  if (selectedDataTrackTypes.length === 0) {
    return (
      <div className="border border-red">
        <PlaceholderChart />
      </div>
    );
  }

  if (chartType === 'combined') {
    return (
      <div className="border border-red">
        <CombinedChart
          data={coloredProcessRecords[0].dataTracks
            .filter((dataTrack) => selectedDataTrackTypes.includes(dataTrack.dataTrackType))
            .map((dataTrack, index) => ({
              color: PROCESS_RECORD_COLORS[index % PROCESS_RECORD_COLORS.length],
              dataPoints: dataTrack.dataPoints!, // NOSONAR
              id: dataTrack.dataTrackId,
              name: dataTrack.displayName,
              yAxisLabel: dataTrack.engineeringUnit,
            }))}
          startTime={coloredProcessRecords[0].startTimestamp.getTime()}
          stopTime={coloredProcessRecords[0].stopTimestamp.getTime()}
        />
      </div>
    );
  }

  return (
    <div className="border border-red">
      <ProcessRecordVisualizationChart
        inSingleView={totalProcessRecordCount === 1}
        coloredProcessRecords={coloredProcessRecords}
        selectedDataTrackTypes={selectedDataTrackTypes}
        timeAlignment={timeAlignment}
      />
    </div>
  );
}

export default ProcessRecordVisualizationChartSelection;
