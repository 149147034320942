/** Single data point to be displayed in the chart. */
export interface DataPointObject {
  /** Timestamp of the data point in ms seconds since start time.
   *
   *  This one of
   *    - a UNIX timestamp (seconds since 1st of january 1970).
   *    - a timestamp in seconds as offset from the charts start time.
   */
  ts: number;
  /** Data value at the given timestamp. */
  v: number;
}

export interface Series {
  /** Unique ID the series can be identified with. */
  id: string;
  /** Human readable name describing the data object. E.g. DO.PV, pH.PV, etc. */
  name: string;
  /** Data to be displayed in the chart. */
  dataPoints: Array<DataPointObject>;
  /** Label to be shown at the y-axis. */
  yAxisLabel: string;
  /** Color used to display the data in the chart. */
  color: string;
}

export interface CombinedChartProps {
  /** Array of data objects to be shown in the chart. */
  data: Series[];
  /** Start time used for the x-axis. */
  startTime: number;
  /** Stop time used for the x-axis. */
  stopTime: number;
  /** Formatter */
  timeStampFormat?: ChartXAxisTimeStampFormat;
}

export enum ChartXAxisTimeStampFormat {
  /**
   * Full date of the time stamp depending on the users locale.
   *
   * @example
   * "18/08/2024 16:00"    // locale: de_DE
   * "8/18/2024 4:00 P.M." // locale: en_US
   *
   * @default
   */
  FullDate,
  /**
   * Time stamps in the format of `DDd HH:MM`.
   *
   * @example
   * "8d 16:00"      // locale: de_DE
   * "64d 4:00 P.M." // locale: en_US
   */
  AccumulativeDay,
  /**
   * Time stamps in the format of `HHh MM:SS`.
   *
   * @example
   * "16h 35:20"
   * "130h 10:00"
   * */
  AccumulativeHour,
}
