import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import useUploadDataTrack, {
  validateDataTrackArray,
} from '../../common/hooks/use-upload-data-track';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';
import useSubmitDataTracks from '../../common/hooks/use-submit-data-tracks';

import {
  DataTrackUploadModalProps,
  DataTrackUploadStageUnion,
  INITIAL_STAGE_STATE,
  StageKey,
  onStage,
} from './data-track-upload-modal.definitions';
import { ModalContent } from './modal-content';

function DataTrackUploadModal({
  trigger,
  defaultOpen,
  processRecordId,
  onOpenChange,
}: DataTrackUploadModalProps) {
  const [stage, setStage] = React.useState<DataTrackUploadStageUnion>(INITIAL_STAGE_STATE);
  const fileType = onStage(
    stage,
    StageKey.Selected,
    StageKey.Uploading,
    StageKey.UploadFailed,
    StageKey.Uploaded,
  )
    ? stage.file.type
    : undefined;

  const { mutate: upload } = useUploadDataTrack(fileType);
  const { mutate: submit } = useSubmitDataTracks(processRecordId);
  const logger = useLogger();

  const onModalOpenChange = (isOpen: boolean) => {
    onOpenChange?.(isOpen);

    if (isOpen === false) {
      setStage({ key: StageKey.Unselected });
    }
  };

  const handleUpload = () => {
    logger.trackEvent(TrackedEvent.UploadCustomDataTrack);
    if (stage.key !== StageKey.Selected && stage.key !== StageKey.UploadFailed) {
      return;
    }

    setStage({
      key: StageKey.Uploading,
      file: stage.file,
      progress: {
        uploading: true,
        progress: 0,
      },
    });

    // 30 MiB is the maximum file size that we expect in the backend,
    //  it needs to be verified in the frontend as well because of the following bug
    //  which needs to be fixed by microsoft: https://jira.dasgip.de/browse/BIOCL-2862
    const thirtyMIB = 30 * 1024 * 1024; // NOSONAR to allow "magic numbers"
    if (stage.file.size > thirtyMIB) {
      setStage({
        key: StageKey.UploadFailed,
        file: stage.file,
        error: {
          name: 'File Size Error',
          message:
            'The selected file is too large. Please select a file with a maximum size of 30 MiB.',
        },
      });

      return;
    }

    upload(
      {
        formData: stage.file,
        onProgress: (newProgress) => {
          setStage({ key: StageKey.Uploading, file: stage.file, progress: newProgress });
        },
      },
      {
        async onSuccess(result) {
          const data = await result.response.json();
          validateDataTrackArray(data);

          setStage({
            data,
            key: StageKey.Uploaded,
            file: stage.file,
            isValid: false,
          });
        },
        async onError(error) {
          setStage({
            key: StageKey.UploadFailed,
            file: stage.file,
            error,
          });
        },
      },
    );
  };

  const handleSubmit = () => {
    if (stage.key !== StageKey.Uploaded && stage.key !== StageKey.SubmitFailed) {
      return;
    }

    setStage({ key: StageKey.Submitting, data: stage.data });

    submit(stage.data, {
      onSuccess: () => {
        setStage({ key: StageKey.Submitted });
      },
      onError: (error) => {
        setStage({ key: StageKey.SubmitFailed, data: stage.data, isValid: stage.isValid, error });
      },
    });
  };

  return (
    <Modal
      defaultOpen={defaultOpen}
      onOpenChange={onModalOpenChange}
      trigger={trigger}
      title={
        <FormattedMessage
          defaultMessage="Upload New Data Track"
          id="TvXSQy"
          description="Upload New Data Track"
        />
      }
    >
      <Modal.Content className="overflow-auto">
        <ModalContent processRecordId={processRecordId} stage={stage} setStage={setStage} />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancelButton">
            {onStage(stage, StageKey.Submitted) ? (
              <FormattedMessage
                description="Data track file upload close button: label for close button"
                defaultMessage="Close"
                id="MAKsur"
              />
            ) : (
              <FormattedMessage
                description="Data track file upload cancel button: label for cancel button"
                defaultMessage="Cancel"
                id="MzqjzR"
              />
            )}
          </Modal.Button>
        </Modal.Close>
        {onStage(
          stage,
          StageKey.Unselected,
          StageKey.Selected,
          StageKey.Uploading,
          StageKey.UploadFailed,
        ) && (
          <Modal.Button
            disabled={onStage(stage, StageKey.Unselected, StageKey.Uploading)}
            variant="highlight"
            onClick={handleUpload}
            data-testid="uploadButton"
          >
            <FormattedMessage
              description="Data track file upload cancel button: label for upload button"
              defaultMessage="Upload"
              id="qk2JQ/"
            />
          </Modal.Button>
        )}
        {onStage(stage, StageKey.Uploaded, StageKey.Submitting, StageKey.SubmitFailed) && (
          <Modal.Button
            disabled={
              stage.key === StageKey.Submitting ||
              (onStage(stage, StageKey.Uploaded, StageKey.SubmitFailed) && !stage.isValid)
            }
            variant="positive"
            onClick={handleSubmit}
            data-testid="submitButton"
          >
            {onStage(stage, StageKey.SubmitFailed) ? (
              <FormattedMessage
                description="Data track submit button: label for retry"
                defaultMessage="Retry"
                id="gaAenf"
              />
            ) : (
              <FormattedMessage
                description="Data track submit button: label for submit "
                defaultMessage="Submit"
                id="LD5gAj"
              />
            )}
          </Modal.Button>
        )}
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackUploadModal;
