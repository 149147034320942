const enum TrackedEvent {
  /**
   *
   */
  Analytics = 'Analytics',

  /**
   *
   */
  Upload = 'Upload',

  /**
   *
   */
  SearchForProcessRecords = 'Search for process records',

  /**
   *
   */
  Compare = 'Compare',

  /**
   *
   */
  SelectProcessRecordFromList = 'Select process record from list',

  /**
   *
   */
  SelectProcessRecordForComparison = 'Select process record for comparison',

  /**
   *
   */
  GoToProcessRecords = 'Go to process records',

  /**
   *
   */
  SearchForDataTracks = 'Search for data tracks',

  /**
   *
   */
  Add = 'Add',

  /**
   *
   */
  UploadCustomDataTrack = 'Upload custom data track',

  /**
   *
   */
  SelectOrUnselectDataTrack = 'Select/unselect data track',

  /**
   *
   */
  DeleteDataTrack = 'Delete data track',

  /**
   *
   */
  DeselectAllOrReset = 'Deselect All / Reset',

  /**
   *
   */
  UpdateOrDeleteAnAttribute = 'Update or delete an attribute',

  /**
   *
   */
  ToggleSplitOrCombinedChart = 'Toggle split/combined chart',

  /**
   *
   */
  Monitoring = 'Monitoring',

  /**
   *
   */
  SelectSingleUnit = 'Select single unit',

  /**
   *
   */
  Reload = 'Reload',

  /**
   *
   */
  GoBackToMonitoringOverview = 'Go Back to Monitoring Overview',

  /**
   *
   */
  SelectReference = 'Select Reference',

  /**
   *
   */
  ConfirmSelectingReferenceCurve = 'Confirm selecting reference curve',

  /**
   *
   */
  StartRemovingReferenceCurve = 'Start removing reference curve',

  /**
   *
   */
  ExportGraph = 'Export graph as image',

  /**
   *
   */
  AddDataTracksToMonitoringOverview = 'Add data tracks to Monitoring Overview',

  /**
   *
   */
  ToggleDataTrackOnMonitoringOverview = 'Toggle data track on Monitoring Overview Filter',

  /**
   *
   */
  RemoveDataTrackOnMonitoringOverview = 'Remove data track from Monitoring Overview Filter',

  /**
   *
   */
  DeleteWorkflow = 'Delete Workflow from Monitoring Overview',

  /**
   *
   */
  LearnMoreAboutDWSConnection = 'Learn More About DWS Connection',

  /**
   *
   */
  SelectTimeSpan = 'Select Time span',

  /**
   *
   */
  ConsentToAppInsightCookie = 'Consent to AppInsights cookie',

  /**
   *
   */
  RevokeConsentToAppInsightCookie = 'Revoke consent to AppInsights cookie',

  /**
   *
   */
  AddCustomDataTrack = 'Add custom data track',

  /**
   *
   */
  DeleteCustomDataTrack = 'Delete custom data track',

  /**
   *
   */
  AddDataPointsToCustomDataTrack = 'Add data points to custom data track',

  /**
   *
   */
  DeleteDataPointsFromCustomDataTrack = 'Delete data points from custom data track',

  /**
   *
   */
  SetYAxisRange = 'Set Y-Axis Range',

  /**
   *
   */
  SetYAxisRangeInMonitoringOverview = 'Set Y-Axis Range in Monitoring Overview',
}

export default TrackedEvent;
