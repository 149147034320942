import React from 'react';
import { Select } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { ChartXAxisTimeStampFormat } from '../../../shared/charts/combined-chart/combined-chart.definitions';
import { DataTrackTimeAlignment } from '../../scenes/process-record-detail/process-record-visualization/process-record-visualization.definitions';

import { ProcessRecordXAxisFormatOptionsProps } from './process-record-x-axis-format-options.definitions';

function ProcessRecordXAxisFormatOptions({
  alignment,
  onChange,
  value = '',
  defaultOpen = false,
}: ProcessRecordXAxisFormatOptionsProps) {
  const intl = useIntl();

  const handleValueChange = (newValue: string) => {
    onChange(Number(newValue));
  };

  const areRelativeOptionsDisabled = alignment === DataTrackTimeAlignment.Absolute;
  const areAbsoluteOptionsDisabled = alignment !== DataTrackTimeAlignment.Absolute;

  const alignmentName = alignment.toString();

  return (
    <Select
      className="!min-w-[9rem]"
      data-testid="x-axis-format-combobox"
      placeholder="Select X-Axis format option"
      onValueChange={handleValueChange}
      value={value}
      defaultOpen={defaultOpen}
      label="Timestamp Format"
    >
      <Select.Item
        disabled={areAbsoluteOptionsDisabled}
        value={ChartXAxisTimeStampFormat.FullDate.toString()}
        title={
          areRelativeOptionsDisabled
            ? intl.formatMessage(
                {
                  description: 'ProcessRecordXAxisFormatOptions: FullDate not available',
                  defaultMessage:
                    'This option is not available when alignment is set to {alignmentName}',
                  id: '06uUC4',
                },
                { alignmentName },
              )
            : undefined
        }
      >
        <FormattedMessage
          description="ProcessRecordXAxisFormatOptions: Button to select full date format"
          defaultMessage="Full date"
          id="wRUF6f"
        />
      </Select.Item>

      <Select.Item
        disabled={areRelativeOptionsDisabled}
        value={ChartXAxisTimeStampFormat.AccumulativeDay.toString()}
        title={
          areRelativeOptionsDisabled
            ? intl.formatMessage(
                {
                  description: 'ProcessRecordXAxisFormatOptions: AccumulativeDay not available',
                  defaultMessage:
                    'This option is not available when alignment is set to {alignmentName}',
                  id: 'oBMag2',
                },
                { alignmentName },
              )
            : undefined
        }
      >
        <FormattedMessage
          description="ProcessRecordXAxisFormatOptions: Button to select precise date format"
          defaultMessage="Days (DDd HH:MM)"
          id="VyHW+O"
        />
      </Select.Item>

      <Select.Item
        disabled={areRelativeOptionsDisabled}
        title={
          areRelativeOptionsDisabled
            ? intl.formatMessage(
                {
                  description: 'ProcessRecordXAxisFormatOptions: AccumulativeHour not available',
                  defaultMessage:
                    'This option is not available when alignment is set to {alignmentName}',
                  id: '4j6f9i',
                },
                { alignmentName },
              )
            : undefined
        }
        value={ChartXAxisTimeStampFormat.AccumulativeHour.toString()}
      >
        <FormattedMessage
          description="ProcessRecordXAxisFormatOptions: Button to select minutes format"
          defaultMessage="Hours (HHh MM:SS)"
          id="6hUZZR"
        />
      </Select.Item>
    </Select>
  );
}

export default ProcessRecordXAxisFormatOptions;
