import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import QKey from '../../../../shared/common/hooks/keys';

import {
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../../../shared/common/types/setup';
import useClient from '../../../../shared/common/hooks/use-client';

// Request data points for a setup
export function useDataTracksFromTimestamp(
  controlProcedureId: string,
  dataTracks: Array<DataTrackFromTimestampItem>,
  setupId: string,
): UseQueryResult<LatestDataPointObject[], Error> {
  const { acquireAccessToken } = useAuthentication();
  const { pmsClient } = useClient();

  return useQuery({
    queryKey: [QKey.SETUPS, QKey.DATA_POINTS, setupId],
    enabled: !!dataTracks?.length,
    retry: false,
    queryFn: async () => {
      pmsClient.authorize(await acquireAccessToken());
      return pmsClient.getMeasurementsFromTimestamp({ dataTracks }, controlProcedureId);
    },
  });
}

// Request data points for a control procedure
export function useDataTracksLastTimestamp() {
  const { acquireAccessToken } = useAuthentication();
  const queryClient = useQueryClient();
  const { pmsClient } = useClient();

  const getLastDataPoints = async (
    controlProcedureId: string,
    dataTracks: Array<DataTrackFromTimestampItem>,
  ): Promise<LatestDataPointObject[]> => {
    try {
      return await queryClient.fetchQuery({
        queryKey: [QKey.DATA_POINTS, controlProcedureId, dataTracks[0].id],
        queryFn: async () => {
          pmsClient.authorize(await acquireAccessToken());
          return pmsClient.getMeasurementsFromTimestamp({ dataTracks }, controlProcedureId);
        },
        retry: false,
      });
    } catch (e) {
      return [];
    }
  };

  return { getLastDataPoints };
}
