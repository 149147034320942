import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ChevronLeftIcon, PageLayout, PlusIcon } from '@biss/react-horizon-web';
import { useQueryClient } from '@tanstack/react-query';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { useAuthContext } from '@biss/react-auth-web';

import QKey from '../../../shared/common/hooks/keys';
import useSetup from '../../common/hooks/use-setup';
import RouteDefinition from '../../../shared/common/routes/routes.definitions';
import { ProcessRecordId } from '../../../shared/common/types/process-record';
import {
  DataTrackBase,
  DataTrackDescriptor,
  DataTrackType,
} from '../../../shared/common/types/setup/data-track';
import DataTrackList from '../../../shared/components/data-track-list';
import { SeriesLegendSelected } from '../../../shared/components/time-series-chart/time-series-chart.definitions';
import useSetupList from '../../common/hooks/use-setup-list';
import { ErrorMessage } from '../../../shared/components/error-message';
import { GenericError } from '../../../shared/common/types/generic-error';
import { ApiError } from '../../../shared/common/types/api-error/api-error';
import Footer from '../../../shared/components/footer';
import useAutomaticUpdates from '../../common/hooks/use-automatic-updates';
import useDocumentVisibility from '../../../shared/common/hooks/use-document-visibility';
import {
  DATA_TRACKS_FROM_TIMESTAMP_INTERVAL,
  MONITORING_DATA_TRACKS,
  RETRY_DELAY,
} from '../../common/types/setup';
import {
  getFirstTimestamp,
  getIsSetupSetting,
  isSameFirstDataPoint,
  isStartGapRecovery,
  getGapRecoveryTimestamp,
  getDataTrackLastTimestamp,
} from '../../common/helpers';
import { useDataTracksLastTimestamp } from '../../common/hooks/use-data-tracks-from-timestamp';
import { ClientError } from '../../../shared/common/types/client-error';
import useSaveDataTracks from '../../common/hooks/use-save-data-tracks';
import DataTrackCreateModal from '../../../shared/components/data-track-create-modal';
import useFeatureFlag from '../../../shared/common/hooks/use-feature-flag';
import FKey from '../../../shared/common/feature-keys';
import { DataTrackItem } from '../../../shared/components/data-track-list/data-track-list.definitions';
import useDeleteDataTrack from '../../common/hooks/use-delete-data-track';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import ReleaseFeatureFlag from '../../../shared/common/types/release-feature-flag';
import BetaFeatureFlag from '../../../shared/common/types/beta-feature-flag';
import BKey from '../../../shared/common/beta-feature-keys';

import {
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../../shared/common/types/setup';

import { DEFAULT_FRACTIONAL_DIGITS } from '../../common/types/data-track';

import {
  getRequestedDataTrackIds,
  deleteDataTrack,
  getDataTrackColorName,
  updateDataTrackColors,
  getWorkflowInfo,
  getSelectedDataTracks,
  getRequestedDataTrackId,
  isGapRecovery,
  getDataTrackIds,
  getDataTracksConflict,
  isFromTimestamp,
  handleDeleteCustomDataTrackSuccess,
  getDataTrackId,
  getDataTracksDescriptors,
  isGetDataPoints,
  isInvalidateSetup,
  getStopTimestamp,
} from './setup-details.helpers';
import TestStream from './test-stream';
import SetupNavigation from './setup-navigation';
import { WorkflowInfo } from './setup-navigation/setup-navigation.definitions';
import SetupDetailsContent from './setup-details-content';
import SetupEvents from './setup-events';
import CustomDataPoints from './custom-data-points';
import { SetupDetailsProps } from './setup-details.definitions';

function SetupDetails({ defaultSidebarOpen }: SetupDetailsProps) {
  const intl = useIntl();
  const logger = useLogger();

  const { id } = useParams();
  const navigate = useNavigate();
  const isDocumentVisible = useDocumentVisibility();
  const queryClient = useQueryClient();
  const autoUpdate = useAutomaticUpdates();
  const storageDataTracks = useReadLocalStorage<Record<string, boolean> | null>(
    MONITORING_DATA_TRACKS,
  );
  const isAddEditOfflineDateEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_ADD_EDIT_OFFLINE_DATA),
  );
  const { account } = useAuthContext();
  const isAddEditOfflineDataBetaPackageEnabled = useFeatureFlag(
    new BetaFeatureFlag(
      BKey.MONITORING_ADD_EDIT_OFFLINE_DATA_BETA_PACKAGE_NAME,
      account?.organizationId,
    ),
  );

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [referenceProcessId, setReferenceProcessId] = useState<ProcessRecordId>('');
  const isDocumentVisiblePrev = useRef<boolean>(isDocumentVisible);
  const dataTracksTimestamp = useRef<DataTrackFromTimestampItem[]>([]);
  const firstTimestampPrev = useRef<number | null>();
  const isDisconnectedPrev = useRef<boolean>(false);
  const gapRecoveryTimestamp = useRef<string | null>();
  const autoUpdateCounter = useRef<number>(0);

  // controls whether the sidebar is open or closed
  const [open, setOpen] = useState(defaultSidebarOpen ?? !isMobile);

  if (id === undefined) {
    throw new Error('An id has to be provided via the route.');
  }

  const { data: setup, isError, error, isLoading } = useSetup(id);
  const { data: setupItems } = useSetupList(autoUpdate);
  const { mutate: deleteCustomDataTrack } = useDeleteDataTrack(
    setup?.controlProcedureId as string,
    id,
  );
  const updatedSetup = setupItems?.find((item) => item.setupId === id);
  const isDisconnected = updatedSetup?.connectionState === 'Disconnected';

  const [lastDataPoints, setLastDataPoints] = useState<LatestDataPointObject[]>([]);
  const [selectedDataTracks, setSelectedDataTracks] = useState<Record<DataTrackType, string>>(
    getSelectedDataTracks(storageDataTracks),
  );

  const [seriesLegendSelected, setSeriesLegendSelected] = useState<SeriesLegendSelected>({});
  const [workflowInfo, setWorkflowInfo] = useState<WorkflowInfo>();
  const [isLoadingSetup, setIsLoadingSetup] = useState<boolean>(true);
  const [isDataTrackModalOpen, setIsDataTrackModalOpen] = useState<boolean>(false);
  const [isDataPointsModalOpen, setIsDataPointsModalOpen] = useState<boolean>(false);
  const [selectedCustomDataTrack, setSelectedCustomDataTrack] = useState<DataTrackDescriptor>();
  const { mutate: saveDataTracks, error: saveDataTracksError, isPending } = useSaveDataTracks(id);

  // set workflow info when receive list of setups and setup details
  useEffect(() => {
    if (workflowInfo) {
      return;
    }
    const workflow = getWorkflowInfo(setupItems, setup?.workflowId);
    setWorkflowInfo(workflow);
  }, [setupItems, setup?.workflowId]);

  // invalidate queries if document is visible after hiding and auto update is disabled
  useEffect(() => {
    if (isInvalidateSetup(isDocumentVisiblePrev.current, isDocumentVisible, autoUpdate)) {
      queryClient.invalidateQueries({ queryKey: [QKey.SETUPS] });
      setIsLoadingSetup(true);
    }
    isDocumentVisiblePrev.current = isDocumentVisible;
  }, [isDocumentVisible]);

  const requestedDataTrackIds = getRequestedDataTrackIds(
    Object.keys(selectedDataTracks),
    setup?.dataTracks,
  );

  const onReferenceProcessRecord = (processRecordId: ProcessRecordId): void => {
    setReferenceProcessId(processRecordId);
  };

  function setDataTypeSelection(types: Record<DataTrackType, string>): void {
    setSelectedDataTracks(types);
  }

  const updateDataTracksTimestamp = (tracks: DataTrackFromTimestampItem[]) => {
    dataTracksTimestamp.current = tracks;
  };

  const deleteDataTracksTimestamp = () => {
    dataTracksTimestamp.current = [];
  };

  const updateIsLoadingSetup = (value: boolean) => {
    if (!isLoading) {
      setIsLoadingSetup(value);
    }
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const isSetupSetting = getIsSetupSetting(setup?.startTimestamp);
    if (isSetupSetting) {
      // invalidate setup details every 1 min for the first 5 min after setup start time
      timeout = setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QKey.SETUPS, id],
        });
      }, RETRY_DELAY);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setup]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { getLastDataPoints } = useDataTracksLastTimestamp();

  const stopGapRecovery = () => {
    firstTimestampPrev.current = null;
    gapRecoveryTimestamp.current = null;
  };

  const gapRecovery = (data: LatestDataPointObject[]) => {
    if (!data.length) {
      stopGapRecovery();
    } else {
      const firstTimestamp = getFirstTimestamp(data);
      if (isSameFirstDataPoint(firstTimestamp, firstTimestampPrev.current)) {
        stopGapRecovery();
      } else {
        firstTimestampPrev.current = firstTimestamp;
      }
    }
  };

  async function getDataPoints() {
    try {
      let lastTimestamps;
      if (isFromTimestamp(autoUpdateCounter.current, gapRecoveryTimestamp.current)) {
        lastTimestamps = getDataTrackLastTimestamp(
          dataTracksTimestamp.current,
          gapRecoveryTimestamp.current,
        );
      } else {
        lastTimestamps = getDataTrackIds(dataTracksTimestamp.current);
        autoUpdateCounter.current = 0;
      }
      if (!lastTimestamps.length || !setup?.controlProcedureId) {
        return;
      }
      const data = await getLastDataPoints(setup.controlProcedureId, lastTimestamps);

      if (data) {
        setLastDataPoints(data);
        if (isGapRecovery(gapRecoveryTimestamp.current)) {
          gapRecovery(data);
        }
        autoUpdateCounter.current += 1;
      }
    } finally {
      timeoutRef.current = setTimeout(() => {
        getDataPoints();
      }, DATA_TRACKS_FROM_TIMESTAMP_INTERVAL);
    }
  }

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      autoUpdateCounter.current = 0;
    }
  };

  // uses to start or stop auto update of last data points
  useEffect(() => {
    if (isGetDataPoints(autoUpdate, isDisconnected, updatedSetup?.stopTimestamp)) {
      getDataPoints();
    } else {
      clearTimeoutRef();
    }
    if (isStartGapRecovery(isDisconnected, isDisconnectedPrev.current)) {
      gapRecoveryTimestamp.current = getGapRecoveryTimestamp(dataTracksTimestamp.current);
    }
    isDisconnectedPrev.current = isDisconnected;
    return () => {
      clearTimeoutRef();
    };
  }, [autoUpdate, isDisconnected, updatedSetup?.stopTimestamp]);

  const onReset = () => {
    const selectedTracks = getSelectedDataTracks(storageDataTracks);
    setDataTypeSelection(selectedTracks);
    setSeriesLegendSelected({});
    const dataTrackIds = getRequestedDataTrackIds(Object.keys(selectedTracks), setup?.dataTracks);
    dataTrackIds?.forEach((trackId: string) => {
      queryClient.invalidateQueries({ queryKey: [QKey.SETUPS, id, trackId] });
    });
  };

  const onDeselectAll = () => {
    setDataTypeSelection({});
    setSeriesLegendSelected({});
    deleteDataTracksTimestamp();
  };

  const setDataTrackSelected = (type: ProcessRecordId, selected: boolean) => {
    if (selected) {
      const trackId = getRequestedDataTrackId(type, setup?.dataTracks);
      queryClient.removeQueries({ queryKey: [QKey.SETUPS, id, trackId] });
      const color = getDataTrackColorName(type, selectedDataTracks);
      setSelectedDataTracks((prevState) => ({ ...prevState, [type]: color }));
    } else {
      setSelectedDataTracks((prevState) => deleteDataTrack(prevState, type));
    }
  };

  const updateSelectedDataTrackColors = () => {
    setSelectedDataTracks(updateDataTrackColors(selectedDataTracks));
  };

  const onChangeSetup = (value: string) => {
    setIsLoadingSetup(true);
    queryClient.removeQueries({ queryKey: [QKey.SETUPS, id] });

    navigate(`${RouteDefinition.Monitoring}/setups/${value}`, {
      relative: 'path',
      replace: true,
    });
  };

  const dataTracks = getDataTracksDescriptors(setup?.dataTracks);

  // delete a data track from setup
  const handleDelete = ({ dataTrackType }: DataTrackItem) => {
    // find the data track that has the same type as the one that is supposed to be deleted
    const dataTrackId = getDataTrackId(dataTracks, dataTrackType);

    deleteCustomDataTrack(dataTrackId, {
      onSuccess: () =>
        handleDeleteCustomDataTrackSuccess(
          dataTrackType,
          selectedDataTracks,
          setSelectedDataTracks,
        ),
    });

    logger.trackEvent(TrackedEvent.DeleteCustomDataTrack);
  };

  const goToOverview = () => {
    queryClient.removeQueries({ queryKey: [QKey.SETUPS] });
    navigate(`${RouteDefinition.Monitoring}/setups`);
  };

  const onSaveDataTrack = (dataTracksCustom: DataTrackBase[]) => {
    if (setup?.controlProcedureId && dataTracksCustom.length) {
      const tracks = dataTracksCustom.map((item) => ({
        ...item,
        fractionalDigits: DEFAULT_FRACTIONAL_DIGITS,
      }));
      saveDataTracks(
        {
          dataTracks: tracks,
          controlProcedureId: setup.controlProcedureId,
        },
        { onSuccess: () => setIsDataTrackModalOpen(false) },
      );
      logger.trackEvent(TrackedEvent.AddCustomDataTrack);
    }
  };

  const dataTracksConflict = getDataTracksConflict(saveDataTracksError);

  const onCreateCustomDataPoints = (dataTrackType: DataTrackType) => {
    const track = dataTracks.find((dt) => dt.dataTrackType === dataTrackType);
    if (!track) {
      throw new Error(
        `Data track with type "${dataTrackType}" could not be found within this setup.`,
      );
    }
    setSelectedCustomDataTrack(track);
    setIsDataPointsModalOpen(true);
  };

  // remove selected custom data track when data points modal is closed and no errors occurred
  const removeSelectedCustomDataTrack = () => {
    if (!isDataPointsModalOpen) {
      setSelectedCustomDataTrack(undefined);
    }
  };

  const stopTimestamp = getStopTimestamp(setup?.stopTimestamp, updatedSetup?.stopTimestamp);

  const sidebarButtonsText = intl.formatMessage({
    defaultMessage: 'Select Data Tracks',
    id: '8WoedZ',
    description: 'Select Data Tracks Sidebar Open And Close Text',
  });

  return (
    <PageLayout sidebarOpen={open} className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar
          sidePanelOpen={open}
          onSidePanelOpenChange={setOpen}
          openText={sidebarButtonsText}
          closeText={sidebarButtonsText}
          pageTitle={
            <SetupNavigation
              workflowInfo={workflowInfo}
              setupId={id}
              setupInfo={
                setup && {
                  title: setup.title,
                  deviceName: setup.deviceName,
                  unit: setup.unit,
                }
              }
              onChangeSetup={onChangeSetup}
            />
          }
          backButton={
            <Button
              kind="secondary"
              mood="neutral"
              onClick={goToOverview}
              leftIcon={<ChevronLeftIcon />}
              data-testid="go-back-to-overview-button"
              className="self-start"
            >
              <FormattedMessage
                description="Button to Monitoring Overview screen"
                defaultMessage="Back to Overview"
                id="eqyKJG"
              />
            </Button>
          }
        />
        <PageLayout.Main.Content className="flex flex-auto flex-col gap-2 overflow-y-auto">
          <div data-testid="setup-details" className="flex flex-col gap-2 px-4">
            {isError ? (
              <ErrorMessage
                error={error as GenericError<ApiError | ClientError>}
                message="An internal error occurred while loading the running unit."
                variant="highlighted"
              />
            ) : (
              <div className="flex h-full flex-col gap-2">
                <SetupDetailsContent
                  setupId={id}
                  removeSelectedCustomDataTrack={removeSelectedCustomDataTrack}
                  selectedCustomDataTrack={selectedCustomDataTrack}
                  isDisconnected={isDisconnected}
                  dataTrackDescriptors={dataTracks}
                  lastDataPoints={lastDataPoints}
                  deleteDataTracksTimestamp={deleteDataTracksTimestamp}
                  updateDataTracksTimestamp={updateDataTracksTimestamp}
                  startTimestamp={setup?.startTimestamp}
                  stopTimestamp={stopTimestamp}
                  inoculationTimestamp={updatedSetup?.inoculationTimestamp}
                  requestedDataTrackIds={requestedDataTrackIds}
                  selectedDataTracks={selectedDataTracks}
                  updateDataTrackColors={updateSelectedDataTrackColors}
                  referenceProcessId={referenceProcessId}
                  onReferenceProcessRecord={onReferenceProcessRecord}
                  seriesLegendSelected={seriesLegendSelected}
                  toggleSeriesLegendSelected={setSeriesLegendSelected}
                  isLoadingSetup={isLoadingSetup}
                  updateIsLoadingSetup={updateIsLoadingSetup}
                />
                <SetupEvents
                  autoUpdate={autoUpdate}
                  systemId={setup?.systemId}
                  unit={setup?.unit}
                  startTimestamp={setup?.startTimestamp}
                  stopTimestamp={stopTimestamp}
                  isLoadingSetup={isLoadingSetup}
                />
                <TestStream />
              </div>
            )}
            <CustomDataPoints
              setupId={setup?.setupId}
              dataTrack={selectedCustomDataTrack}
              open={isDataPointsModalOpen}
              onChangeModal={setIsDataPointsModalOpen}
              controlProcedureId={setup?.controlProcedureId}
            />
          </div>

          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
      {setup && (
        <PageLayout.Sidebar
          onOpenChange={setOpen}
          isOpen={open}
          openText={sidebarButtonsText}
          closeText={sidebarButtonsText}
        >
          <PageLayout.Sidebar.Title className="whitespace-nowrap">
            <FormattedMessage
              description="Data Track Selection Sidebar Title"
              defaultMessage="Data Track Selection"
              id="noSwjL"
            />
          </PageLayout.Sidebar.Title>

          <DataTrackList
            onDeselectAll={onDeselectAll}
            showTitle={false}
            onReset={onReset}
            items={dataTracks}
            selectedItems={Object.keys(selectedDataTracks)}
            onDelete={handleDelete}
            canDeleteCustomDataTracks
            onSelect={setDataTrackSelected}
            isCustomDataPoints
            onCreateCustomDataPoints={onCreateCustomDataPoints}
            actions={
              isAddEditOfflineDateEnabled &&
              isAddEditOfflineDataBetaPackageEnabled && (
                <DataTrackCreateModal
                  isOpen={isDataTrackModalOpen}
                  setIsOpen={setIsDataTrackModalOpen}
                  dataTrackTypes={[
                    ...dataTracks.map((track) => track.dataTrackType),
                    ...dataTracksConflict,
                  ]}
                  trigger={
                    <Button
                      kind="secondary"
                      mood="neutral"
                      leftIcon={<PlusIcon />}
                      data-testid="newDataTrackButton"
                    />
                  }
                  onSave={onSaveDataTrack}
                  isError={isError && !dataTracksConflict.length}
                  isPending={isPending}
                  isDisabled={isPending}
                />
              )
            }
          />
        </PageLayout.Sidebar>
      )}
    </PageLayout>
  );
}

export default SetupDetails;
