import { many } from '../../common/utils';
import { DataPointObject } from '../../components/time-series-chart';

import {
  COMBINED_CHART_GRID_HEIGHT,
  COMBINED_CHART_GRID_OFFSET,
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_LEGEND_HEIGHT,
  COMBINED_CHART_RIGHT_OFFSET_PX,
  COMBINED_CHART_TOOLBOX_HEIGHT,
} from './combined-chart.config';

export function getGridEchartsConfig(amountOfLines: number) {
  return {
    // reserve space for each y-axis on the left of the chart
    left: amountOfLines * COMBINED_CHART_LEFT_OFFSET_PX,
    top: COMBINED_CHART_TOOLBOX_HEIGHT + COMBINED_CHART_LEGEND_HEIGHT + COMBINED_CHART_GRID_OFFSET,
    right: COMBINED_CHART_RIGHT_OFFSET_PX,
    height: COMBINED_CHART_GRID_HEIGHT,
  };
}

export function getTooltipEchartsConfig(amountOfLines: number) {
  return many(
    () => ({
      trigger: 'axis',
      show: true,
      order: 'seriesDesc',
    }),
    amountOfLines,
  );
}

export function getLegendEchartsConfig(data: Array<{ name: string; color: string }>) {
  return {
    data: data.map((entry) => ({
      name: entry.name,
      itemStyle: {
        color: entry.color,
      },
      lineStyle: {
        color: entry.color,
      },
    })),
    top: COMBINED_CHART_TOOLBOX_HEIGHT,
    height: COMBINED_CHART_LEGEND_HEIGHT,
  };
}
export function getDatasetEchartsConfig(
  data: Array<{ id: string; dataPoints: Array<DataPointObject> }>,
) {
  return data.flatMap((entry) => ({
    id: entry.id,
    source: entry.dataPoints,
    dimensions: ['ts', 'v'],
  }));
}

export function getSeriesEchartsConfig(data: Array<{ color: string; name: string }>) {
  return data.map((entry, index) => ({
    type: 'line',
    lineStyle: {
      type: 'line',
      width: 2,
    },
    xAxisIndex: 0,
    yAxisIndex: index,
    name: entry.name,
    datasetIndex: index,
    animation: false,
    showSymbol: false,
    color: entry.color,
  }));
}
