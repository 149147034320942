import {
  DataTrack,
  DataTrackType,
  StructuredProcessRecordObject,
} from '../../../../shared/common/types/process-record';

export interface ProcessRecordVisualizationProps {
  processRecords: StructuredProcessRecordObject[];
  totalProcessRecordCount?: number;
  pageTitle?: string;
  defaultSidebarOpen?: boolean;
}

export enum DataTrackTimeAlignment {
  RelativeToStartTime,
  RelativeToInoculationTime,
  Absolute,
}

export type TabKey = 'data-track-selection' | 'data-how-integration';

/**
 * default datatracks to select when viewing process records
 */
export const DEFAULT_DATA_TRACK_TYPES = ['pH.PV', 'DO.PV', 'T.PV'] satisfies DataTrackType[];

/**
 * data track with a reverse reference to its parent @see processRecord,
 * and an added @see color field
 * */
export interface MarkedDataTrack extends DataTrack {
  processRecord: StructuredProcessRecordObject;
  color: string;
}

export type DataTracksByType = Record<DataTrackType, MarkedDataTrack[]>;
